'use client'

import { datadogRum } from '@datadog/browser-rum'
import { useEffect } from 'react'

import { checkConsentGroup, ConsentGroup } from '@marketplace-web/shared/consent'
import { cookiesDataByName, createCookieManager } from '@marketplace-web/shared/cookies'
import { clientSideMetrics, clientMetricsOtel } from '@marketplace-web/shared/metrics'
import { PageId } from '@marketplace-web/shared/request'

import { serverSide } from '@marketplace-web/shared/environment'

import { initialiseMonitoring } from './utils'

initialiseMonitoring()

if (!serverSide) {
  const isSandbox =
    window.location.host.includes('sandbox') || window.location.host.includes('localhost')

  const params = new URLSearchParams(document.location.search)
  const isOtelTestEnabled = params.get('otel_test') !== null

  const env = isSandbox ? 'sandbox' : 'production'
  const samplingRate = isSandbox ? 1 : 0.1

  // TODO: Setup as a single env variable on kube-rollout
  // and control `collectorUrl` through there.
  const collectorUrl = isSandbox
    ? process.env.NEXT_PUBLIC_OTEL_COLLECTOR_SANDBOX_URL
    : process.env.NEXT_PUBLIC_OTEL_COLLECTOR_URL

  if (collectorUrl) {
    clientMetricsOtel.initialize({
      serviceName: 'marketplace_web',
      version: process.env.NEXT_PUBLIC_RELEASE_VERSION,
      withConsoleExporter: isSandbox,
      samplingRate: isOtelTestEnabled ? 1 : samplingRate,
      collectorUrl,
      env,
    })
  }
}

type Props = {
  pageId: PageId
}

const Monitoring = ({ pageId }: Props) => {
  useEffect(() => {
    datadogRum.setGlobalContextProperty('page', pageId)
    clientSideMetrics.counter('page_load', { page: pageId }).increment()
  }, [pageId])

  useEffect(() => {
    const handleBannerLoaded = () => {
      const optanonConsentCookie = createCookieManager().get(cookiesDataByName.OptanonConsent)
      const isPerformanceCookiesEnabled = checkConsentGroup(
        ConsentGroup.Performance,
        optanonConsentCookie,
      )

      datadogRum.setTrackingConsent(isPerformanceCookiesEnabled ? 'granted' : 'not-granted')
    }

    window.addEventListener('bannerLoaded', handleBannerLoaded)

    return () => window.removeEventListener('bannerLoaded', handleBannerLoaded)
  }, [])

  return null
}

export default Monitoring
